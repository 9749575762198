import React, {useState} from 'react'



function Summary(props){
    if (props.text.length !== 0) {
        const newText = props.text.trim().split("\n").filter(line => line.trim() !== "").join('');
        const afterExt = newText.split(/[ ]+/);
        return (
            <div className="container my-2">
                <h3>Your Text summary</h3>
                <p>
                    {afterExt.length} word(s) and {props.text.length} character(s)
                    <br />
                   <strong> {0.008 * (props.text.split(" ").length)} </strong> minutes need to read
                </p>
                <h4>Preview</h4>
                <p>{props.text}</p>
            
            </div>
            )
    }else{
        return (
            <p className='m-5'>Write something above box to Analyze...</p>
        )
        
    }
    
}
export default function TextForm(props) {
    const [text, setText] = useState("")

    const handleUpClick = () => {
        const newText = text.toUpperCase();
        setText(newText);
        // props.showAlert("Converted to UpperCase!", "success");
    };

    const handleLowClick = () => {
        const newText = text.toLowerCase();
        setText(newText);
        // props.showAlert("Converted to LowerCase!", "success");
    };
    
    const handleClearClick = () => {
        const newText = "";
        setText(newText);
        // props.showAlert("Text cleared !", "danger");
    };
    
    const handleCopyClick = async () => {
        const newText = document.getElementById('myBox');
        newText.focus();
        newText.setSelectionRange(0, newText.value.length);
        try {
            window.navigator.clipboard.writeText(newText.value);
            // props.showAlert("Copied! to your clipboard", "info");
        } catch (err) {
            // props.showAlert("Your device not supported to programming copy", "danger");
        }
      
      };
    
    const handleExClick = () => {
        const newText = text.split(/[ ]+/);
        setText(newText.join(" ")); 
    };

    const minifier = () => {
        const newText = text.trim().split("\n").filter(line => line.trim() !== "").join('');
        const afterExt = newText.split(/[ ]+/);
        console.log(afterExt);
        setText(afterExt.join(''))
    };

    const onChangeHandle = (event) => {
        setText(event.target.value);
    };
  return (
    <>
    <div className='container'>
        <div className="mb-3">
            <h1>{props.heading}</h1>
            <textarea className="form-control" value={text} onChange={onChangeHandle} id="myBox" rows="8"></textarea>
        </div>
        <button className='btn btn-primary' onClick={handleUpClick}>UpperCase</button>
        <button className='btn btn-primary m-2' onClick={handleLowClick}>LowerCase</button>
        <button className='btn btn-danger m-2' onClick={handleClearClick}>Clear</button>
        <button className='btn btn-warning m-2' onClick={handleCopyClick}>Copy</button>
        <button className='btn btn-warning m-2' onClick={handleExClick}>Remove Space</button>
        <button className='btn btn-warning m-2' onClick={minifier}>Minifier</button>
    </div>
    <Summary text={text}/>
    </>
  )
}
