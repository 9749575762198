import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import PropTypes from 'prop-types'

export default function Navbar(props) {
    const [mode, setMode] = useState('dark')

    const darkToggler = () => {
        if (mode === 'light') {
            setMode('dark');
        }else{
            setMode('light');
        };
        
    document.body.setAttribute('data-bs-theme', `${mode}`)
        // props.showAlert(`${mode} Mode Enabled`, "info");
    };
  return (
    <nav className="navbar navbar-expand-lg bg-body-tertiary">
    <div className="container-fluid">
        <Link className="navbar-brand" to="/">{props.title}</Link>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
            <Link className="nav-link active" aria-current="page" to="/text-utils">TextUtils</Link>
            </li>
            {props.items.map((item) => (
                <li key={item.key} className="nav-item">
                <Link className="nav-link" to={item.link}>{item.name}</Link>
                </li>
            ))}
            <li className="nav-item">
                <button onClick={darkToggler} className="btn btn-primary"> &#127769;</button>
            </li>
            
        </ul>
        <form className="d-flex" role="search">
            <input className="form-control me-2" type="search" placeholder="Search" aria-label="Search"/>
            <button className="btn btn-outline-success" type="submit">Search</button>
        </form>
        </div>
    </div>
    </nav>

  )
}
Navbar.propTypes = {
    title: PropTypes.string
}

Navbar.defaultProps = {
    title: "Title Here"
}
