
import './App.css';
import { Outlet } from "react-router-dom";
import { useState } from 'react';
import Alert from './components/Alert';
import Navbar from './components/Navbar';

function App() {
  const items = [{key: 1, name:'About', link:'/about'}];
    
  const [alert, setalert] = useState(null);
  const showAlert = (message, type) => {
    setalert({
      msg: message,
      type: type
    });
    setTimeout(() => {
      setalert(null)
    }, 2000);
  };


  return (
    <>
    <Navbar title="TextUtils" items={items}/>
    <Alert alert={alert} />
    <div className="container my-4">
      <Outlet alert={showAlert}/>
    </div>
    </>
  );
}


export default App;
